import React, { FC } from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Formik, FormikHelpers } from 'formik';
import OtpInput from 'src/legacy/components/Auth/OtpInput';
import {
  AUTH_STATES,
  MFA_CODE_LENGTH,
  MFA_INPUT_DESCRIPTION,
  MFA_INPUT_TITLE,
  INVALID_CODE,
  SESSION_EXPIRY_ERR_MSG,
} from 'src/constants/authConsts';
import { authScreenStyles } from 'src/legacy/components/Auth/styles';

import { IAuthenticatorReturn } from 'src/legacy/components/AwsAmplify';
import Button from 'src/legacy/components/Button';
import { ClientAuthContainer } from 'src/legacy/components/Auth/ClientAuthContainer';
import { ensureApiError } from 'src/utils/Errors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    otpForm: {
      display: 'flex',
      justifyContent: 'space-evenly',
      flexDirection: 'column',
    },
    ...authScreenStyles(theme),
  }),
);

const schema = Yup.object().shape({
  otp: Yup.string().required(INVALID_CODE).length(6, INVALID_CODE),
});

interface VerifyMfaValues {
  otp: string;
}

export interface VerifyMfaProps extends IAuthenticatorReturn {
  user?: CognitoUser;
}

// VerifyMfaForm presents an otp input to the client
// This is designed to be used in the ClientLogin component,
// and it uses its onStateChange to signal sign in completion
const VerifyMfaForm: FC<VerifyMfaProps> = ({
  // authData contains the user for which sign in has been triggerred
  user,
  // onStateChange is used to complete the sign in flow
  onStateChange,
}) => {
  const classes = useStyles();

  const onSubmit = async (
    values: VerifyMfaValues,
    { setFieldError }: FormikHelpers<VerifyMfaValues>,
  ) => {
    try {
      // complete sign in with otp
      const signedInuser = await Auth.confirmSignIn(
        user,
        values.otp,
        'SOFTWARE_TOKEN_MFA',
      );

      // complete sign in flow, as defined in ClientLogin
      onStateChange(AUTH_STATES.SIGNED_IN, signedInuser);
    } catch (e) {
      const err = ensureApiError(e);
      if (err.code === 'CodeMismatchException') {
        setFieldError('otp', INVALID_CODE);
      } else if (err.message.includes('session is expired')) {
        setFieldError('otp', SESSION_EXPIRY_ERR_MSG);
      }

      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  const initialValues: VerifyMfaValues = {
    otp: '',
  };

  return (
    <ClientAuthContainer
      title={MFA_INPUT_TITLE}
      description={MFA_INPUT_DESCRIPTION}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          handleSubmit,
          touched,
          values,
          isSubmitting,
          setFieldValue,
          submitForm,
        }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            className={clsx(classes.formContainer, classes.otpForm)}
          >
            <OtpInput
              value={values.otp}
              onChange={async (otp) => {
                await setFieldValue('otp', otp, true);

                // auto-verify otp once last digit is entered
                if (otp.length === MFA_CODE_LENGTH) {
                  await submitForm();
                }
              }}
              error={touched.otp && errors.otp}
            />
            <Button
              isLoading={isSubmitting}
              size="large"
              className={classes.submitButton}
              fullWidth
              type="submit"
              variant="outlined"
              htmlId="verifyTokenButton"
              progressColor="primary"
              disabled={values.otp?.length !== MFA_CODE_LENGTH}
            >
              Verify Token
            </Button>
          </form>
        )}
      </Formik>
    </ClientAuthContainer>
  );
};

export default VerifyMfaForm;
