export enum UsernameAttributes {
  EMAIL = 'email',
  PHONE_NUMBER = 'phone_number',
  USERNAME = 'username',
}

export interface IAuthPieceProps {
  authData?: any;
  authState?: string;
  hide?: any;
  onAuthEvent?: any;
  onStateChange?: (state: string, data?: any) => void;
  track?: () => void;
  theme?: any;
  usernameAttributes?: UsernameAttributes;
}

export interface IAuthPieceState {
  username?: any;
  requestPending?: boolean;
}

export interface ISignInProps extends IAuthPieceProps {
  federated?: any;
  override?: any;
}

export interface ISignInState extends IAuthPieceState {
  loading?: boolean;
}

export enum AuthComponentType {
  LoginFormComponent = 'LoginForm',
  ForgotPasswordFormComponent = 'ForgotPasswordForm',
  ConfirmRegisterFormComponent = 'ConfirmRegisterForm',
  RegisterFormComponent = 'RegisterForm',
  RequireNewPasswordFormComponent = 'RequireNewPasswordForm',
  VerifyMfaFormComponent = 'VerifyMfaForm',
}

export interface AuthChildComponentData {
  componentType: AuthComponentType;
  key: string;
  extraProps?: unknown;
}

export interface IAuthenticatorProps {
  authData?: any;
  authState?: string;
  onStateChange?: (authState: string, data?: any) => void;
  hideDefault?: boolean;
  children: AuthChildComponentData[];
  errorMessage: () => null;
}

export interface IAuthenticatorState {
  authData?: any;
  authState: string;
  error?: string;
}
export interface IInputsState {
  email?: string;
  phone_number?: string;
  username?: string;
  password?: string;
  code?: string;
}

export interface IAuthenticatorReturn {
  key: string;
  authState: string;
  authData: any;
  onStateChange: (state: any, data?: any) => void;
  handleInputChange: (evt: any) => void;
  changeState: (state: any, data?: any) => void;
  checkContact: (user: any) => void;
  checkUser: () => Promise<void>;
  inputs: IInputsState;
  hide: Array<any>;
}
