import React, { useContext, useEffect } from 'react';
import { PortalConfigContext } from 'src/context';
import PortalLogo from 'src/legacy/components/Layout/PortalLogo';
import { AuthScreenPortalBadge } from 'src/legacy/components/Auth/AuthScreenPortalBadge';
import { getLoginImageURL } from 'src/utils/ImageUtils';
import PortalFullLogo from 'src/legacy/components/Layout/PortalFullLogo';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import clsx from 'clsx';

const GOOGLE_AUTH_CARD_HEIGHT = 580;
const AUTH_CARD_HEIGHT = 500;

interface AuthContainerProps {
  title?: string;
  description?: string;
  children: React.ReactNode;
  classes?: {
    card?: string;
  };
}

export const ClientAuthContainer: React.FC<AuthContainerProps> = ({
  title,
  description,
  children,
  classes,
}) => {
  const portalConfig = useContext(PortalConfigContext);
  const googleAuthEnabled = !portalConfig.features.disableGoogleSignIn;
  const isMobileScreen = useMediaQuery('sm', 'max');
  const hasBannerImage = useMediaQuery('xl', 'min');

  // The image height and width depends on whether google auth is enabled or not.
  // For google auth enabled: 580px height to accommodate the additional button
  // For standard auth: 500px height is sufficient
  const coverImageSize = portalConfig.features.disableGoogleSignIn
    ? AUTH_CARD_HEIGHT
    : GOOGLE_AUTH_CARD_HEIGHT;

  /**
   * Generates the auth image URL with proper sizing based on device pixel ratio.
   * Uses a multiplier capped at 2x to prevent loading unnecessarily large images
   * while still supporting high DPI displays.
   * @returns {string} Optimized auth image URL with sizing parameters
   */
  const getAuthImageSrc = () => {
    const devicePixelRatio =
      typeof window === 'undefined' ? 1 : window.devicePixelRatio;
    const pxRatioMultiplier = Math.min(devicePixelRatio, 2);
    return getLoginImageURL(portalConfig.assets.authImage.signInUrl || '', {
      resize: {
        width: coverImageSize * pxRatioMultiplier,
        height: coverImageSize * pxRatioMultiplier,
      },
    });
  };

  // Track image loading state to handle fade-in animation
  const [isImageLoaded, setIsImageLoaded] = React.useState(false);
  const imageSrc = getAuthImageSrc();
  useEffect(() => {
    if (!hasBannerImage) return;

    // Preload image to ensure smooth transition
    const image = new Image();
    image.src = imageSrc;

    const handleImageLoaded = () => {
      setIsImageLoaded(true);
    };
    image.addEventListener('load', handleImageLoaded);

    return () => {
      image.removeEventListener('load', handleImageLoaded);
    };
  }, [getAuthImageSrc]);

  // Only show the full logo if it's configured in portal settings
  // and not using a dummy image placeholder
  const showFullLogo =
    Boolean(portalConfig.assets.logo.logoImageUrl) &&
    !portalConfig.assets.logo.logoImageUrl.includes('dummyimage');

  return (
    <div
      className={clsx(
        'h-full',
        'flex',
        'items-center',
        'justify-center',
        'flex-col',
        'm-0',
        'm-auto',
        'sm:max-w-lg',
        'xl:max-w-6xl',
      )}
    >
      <div
        className={clsx(
          'drop-shadow-sm',
          'rounded-lg',
          'bg-white',
          'flex',
          'relative',
          'overflow-hidden',
          'h-full',
          'w-full',
          'min-w-96',
          googleAuthEnabled ? 'xl:w-[1120px]' : 'xl:w-[1000px]',
          classes?.card,
        )}
      >
        <div
          className={clsx(
            'flex',
            'w-full',
            'flex-col',
            'h-full',
            'xl:w-1/2',
            'pt-16',
            'px-16',
            'pb-12',
            'box-border',
            '[&>form]:grow-0',
            'overflow-y-auto',
          )}
        >
          <div
            className={clsx(
              'flex',
              'justify-center',
              'items-center',
              'w-full',
              'mx-auto',
              title ? 'mb-0' : 'mb-10',
            )}
          >
            {showFullLogo ? <PortalFullLogo logoHeight={60} /> : <PortalLogo />}
          </div>
          {title && (
            <div
              className={clsx(
                'w-full',
                'flex',
                'items-center',
                'flex-col',
                'mt-4',
                'mx-0',
                'mb-10',
                'gap-2',
              )}
            >
              <BaseTypography fontType="18Medium">{title}</BaseTypography>
              {description && (
                <BaseTypography className="text-slate-500" fontType="13Regular">
                  {description}
                </BaseTypography>
              )}
            </div>
          )}

          {children}
          {isMobileScreen && <AuthScreenPortalBadge isAuthScreen={false} />}
        </div>
        <div
          className={clsx(
            'hidden',
            'xl:flex',
            'xl:w-1/2',
            'bg-cover',
            'bg-center',
            'text-white',
            'flex-col',
            'justify-end',
            'transition-opacity',
            'border-r-8',
            isImageLoaded ? 'opacity-100' : 'opacity-0',
          )}
          style={{
            backgroundImage: `url(${imageSrc})`,
          }}
        />
      </div>
      {!isMobileScreen ? (
        <div className="min-h-20">
          <AuthScreenPortalBadge isAuthScreen={false} />
        </div>
      ) : null}
    </div>
  );
};
