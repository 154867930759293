import { useEffect, useState } from 'react';

const breakpoints = {
  xs: 480,
  sm: 640,
  md: 760,
  lg: 960,
  xl: 1280,
  '2xl': 1536,
};

/**
 * Returns true if the screen width is greater than the specified breakpoint.
 */
export const useMediaQuery = (
  query: KeyOf<typeof breakpoints>,
  behavior: 'min' | 'max' = 'min',
  initialValue: boolean = false,
) => {
  const [result, setResult] = useState(initialValue);
  useEffect(() => {
    const evaluateWindowSize = () => {
      setResult(
        behavior === 'min'
          ? window.innerWidth >= breakpoints[query]
          : window.innerWidth <= breakpoints[query],
      );
    };
    addEventListener('resize', evaluateWindowSize);
    evaluateWindowSize();
    return () => removeEventListener('resize', evaluateWindowSize);
  }, [setResult]);
  return result;
};
