import { Box, Link, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { PortalConfigContext } from 'src/context';
import { getPublicImageURL } from 'src/utils/ImageUtils';

interface PortalLogoProps {
  isOnFooter?: boolean;
  isPreviewLogo?: boolean;
  logoUrlPreview?: string;
}

const useStyles = makeStyles(() => ({
  root: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  logo: {
    height: '60px',
    width: '60px',
  },
  previewLogo: {
    height: '19px',
    width: '19px',
  },
}));

const PortalLogo = (props: PortalLogoProps) => {
  const { isOnFooter = false, isPreviewLogo = false, logoUrlPreview } = props;
  const portalConfig = useContext(PortalConfigContext);
  const classes = useStyles();

  const logoPath = React.useMemo(() => {
    const url = isPreviewLogo
      ? logoUrlPreview
      : portalConfig.assets.logo.iconImageUrl;
    if (url) return getPublicImageURL(url);
    return '';
  }, [isPreviewLogo, logoUrlPreview, portalConfig]);

  return (
    <Link href="/" className={classes.root}>
      <Box display="flex" alignItems="center">
        {logoPath && (
          <img
            className={clsx(classes.logo, {
              [classes.previewLogo]: isPreviewLogo,
            })}
            src={logoPath}
            alt="logo"
          />
        )}
        {!logoPath && portalConfig.name && (
          <Box display="flex" alignItems="center" ml={1}>
            <Typography variant={isOnFooter ? 'h4' : 'h2'}>
              {portalConfig.name}
            </Typography>
          </Box>
        )}
      </Box>
    </Link>
  );
};

export default PortalLogo;
