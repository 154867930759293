import React, { useContext, useState, useEffect } from 'react';
import { Auth, Hub, Logger } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { CognitoUser } from 'amazon-cognito-identity-js';
import flagsmith from 'flagsmith';
import { HubCallback } from '@aws-amplify/core';
import {
  AuthChildComponentData,
  AuthComponentType,
  Authenticator,
} from 'src/legacy/components/AwsAmplify';
import {
  AUTH_STATES,
  SIGNED_IN_WITH_GOOGLE_FAILED_EMAIL_KEY,
  SIGNED_IN_WITH_PROXY_USER_KEY,
} from 'src/constants/authConsts';
import history from 'src/history';
import { RouteContext, ContextProps, PortalConfigContext } from 'src/context';
import 'src/legacy/components/Layout/Layout.css';
import { updateUserId } from 'src/store/user/actions';
import {
  alertSnackbar,
  clearAlertMessage,
  toggleAutoSignIn,
} from 'src/store/ui/actions';
import { LoaderContainer } from 'src/legacy/components/Loading';
import { RootState } from 'src/store';
import { UserErrorMessage } from 'src/constants/errorConsts/errorCodesConsts';
import { OnboardingPageQueryParams } from 'src/legacy/components/Onboarding/onboardingTypes';
import {
  GetSignInMetadata,
  GetSignInMetadataInput,
  logoutFromHostedUi,
  getPreferredUsername,
} from 'src/utils/AuthUtils';
import {
  CognitoSignedInUserPayload,
  CognitoUserWithAttributes,
} from 'src/utils/CognitoUtils';
import { LoginPageLayout } from 'src/legacy/components/UI/Layouts/LoginPageLayout';
import { useAdminProxy } from 'src/hooks/useAdminProxy';
import clsx from 'clsx';
import UsersClient from 'src/clients/UsersClient';

const logger = new Logger('ClientLogin');

const CLIENT_UNAUTH_PAGE_FLAGSMITH_ID = 'unAuth';

const excludedNextQueryParams = [
  'next',
  'step',
  'email',
  'password',
  'poolId',
  'magic_link',
  'auth',
  'private-stack',
];

interface AuthStateData {
  challengeName?: string;
}

interface AutoSignInProps {
  username: string;
  password: string;
}

// this is a Type guard to check if authStateValue is userWithAttributes
// the autoStateValue can be set from different states in the auth flow
// so we need to check if the user is of type CognitoUserWithAttributes
export function IsUserWithAttributes(
  obj: any,
): obj is CognitoUserWithAttributes {
  return obj && 'attributes' in obj;
}

export function IsUserSignedInPayload(
  obj: any,
): obj is CognitoSignedInUserPayload {
  return obj && 'signInUserSession' in obj;
}

export const LoginLayout: React.FC<{ className?: string }> = ({
  children,
  className,
}) => {
  return (
    <LoginPageLayout className={clsx('bg-primary-hover', className)}>
      <div className="[&>div:last-of-type]:h-full sm:pt-16 relative p-0 h-full">
        {children}
      </div>
    </LoginPageLayout>
  );
};

export const ClientLogin: React.FC = () => {
  const [authState, setAuthState] = useState<string>('');
  const [authStateData, setAuthStateData] = useState<AuthStateData>({});
  const context: ContextProps = useContext(RouteContext);
  const portalConfig = useContext(PortalConfigContext);
  const isAutoSignIn = useSelector((state: RootState) => state.ui.isAutoSignIn);
  const { startProxy } = useAdminProxy();

  const { query } = context;
  const { step, action, email, magic_link, link_expired } = query as {
    step: string;
    action: string;
    email: string;
    magic_link: string;
    link_expired?: boolean;
  };

  const isProcessingGoogleAuth = useSelector(
    (state: RootState) => state.auth.processingCallback,
  );
  const isProcessingMagicLink = query.magic_link && query.email;
  const isProcessingAuth = isProcessingGoogleAuth || isProcessingMagicLink;

  const dispatch = useDispatch();
  // Amplify Auth keeps Authenticator constructed and keep reference to this functions
  // so use authenticator reference to check if it is mounted
  const authenticator = React.useRef<HTMLDivElement>(null);

  const navigate = (path: string) => {
    dispatch(clearAlertMessage());
    history.push(path);
  };

  const authListenerHandler: HubCallback = (data) => {
    if (
      data &&
      data.payload &&
      data.payload.data &&
      data.payload.data.message
    ) {
      // TODO: temproray solution, change error message
      const authErrorMsg =
        'PreSignUp failed with error A user with the same email address exists.';
      if (data.payload.data.message === authErrorMsg) {
        /* eslint-disable no-param-reassign */
        data.payload.data.message =
          "An account with this email already exists. If you don't remember your password try Forgot Password";
      }

      if (
        data.payload.event !== 'signIn_failure' &&
        !data.payload.data.message.includes('UserMigration failed')
      ) {
        // if error is not related to sign in or user migration, show error message from server
        dispatch(alertSnackbar({ errorMessage: data.payload.data.message }));
      } else {
        dispatch(
          alertSnackbar({ errorMessage: UserErrorMessage.invalidCredentials }),
        );
      }
    }
  };

  const startAutoSignIn = async (data: AutoSignInProps & AuthStateData) => {
    dispatch(toggleAutoSignIn());
    const { username, password } = data;
    const user: CognitoUser = await Auth.signIn(
      username,
      password,
      GetSignInMetadata(query as GetSignInMetadataInput),
    );

    dispatch(toggleAutoSignIn());

    if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
      // set the signing in user as auth state data
      // the sign in flow can be resumed using this user, once the
      // otp input has been received
      setAuthStateData(user);
      setAuthState(AUTH_STATES.CONFIRM_SIGN_IN);
    } else {
      navigate('/');
    }
  };

  const handleRedirect = (data?: AutoSignInProps & AuthStateData) => {
    let queryString = '';
    if (query && query.next) {
      Object.keys(query).forEach((key) => {
        if (!excludedNextQueryParams.includes(key)) {
          queryString = `${queryString ? `${queryString}&` : ''}${key}=${
            query[key as keyof ContextProps['query']]
          }`;
        }
      });
      const queryConcatenation =
        queryString.includes('?') || query.next.includes('?') ? '&' : '?';
      const queryTail = queryString
        ? `${queryConcatenation}${queryString}`
        : '';
      navigate(`${query.next}${queryTail}`);
    } else {
      const { nextOnboardingStep } = query as OnboardingPageQueryParams;
      if (nextOnboardingStep && IsUserWithAttributes(data)) {
        const username = `${data.attributes?.given_name} ${data?.attributes?.family_name}`;
        navigate(
          `/onboarding?step=${nextOnboardingStep}&name=${username}&prefill_email=${data.attributes?.email}`,
        );
        return;
      }

      // go to home page if no next query param
      navigate('/');
    }
  };

  /**
   * This function tracks the auth state
   * and redirects to the appropriate page according to the auth state
   * @param authStateValue: auth state
   * @param data: auth state data
   * @returns
   */
  const handleStateChange = async (
    authStateValue: string,
    data: AutoSignInProps & AuthStateData,
  ) => {
    if (!authenticator.current) {
      return;
    }

    if (authStateValue === 'autoSignIn' && data) {
      try {
        startAutoSignIn(data);
      } catch (error) {
        return;
      }
    }

    dispatch(clearAlertMessage());
    setAuthStateData(data);
    setAuthState(authStateValue);

    if (authStateValue && authStateValue === AUTH_STATES.SIGNED_UP && data) {
      Hub.remove('auth', authListenerHandler);
      try {
        startAutoSignIn(data);
      } catch (error) {
        return;
      }
    }

    // in sign up with google flow, after authenticating with google
    // we want to keep the user in the same page and
    // skip the auto sign in flow.
    if (action === 'signup') {
      return;
    }

    if (authStateValue && authStateValue === AUTH_STATES.SIGNED_IN) {
      let signedInUserEmail = '';
      if (IsUserWithAttributes(data)) {
        signedInUserEmail = data.attributes.email;
        flagsmith.setTrait('email', signedInUserEmail);
      }

      // If user has been signedIn, check if the preferred_username returned in the auth payload
      // matches the expected preferred_username. The expected preferred_username is the `portalId|email`.
      // If there is a mis-match then it means that the user identity is connected to a different portal.
      // This would happen when a user logged in with google and the first email found was connected to
      // a different portal. In this case, we need to proxy the user to the correct portal.
      let payload;
      if (IsUserSignedInPayload(data)) {
        ({ payload } = data.signInUserSession.idToken);
        const expectedPreferredUsername = getPreferredUsername({
          email: payload.email,
          viewMode: portalConfig.viewMode,
          portalId: portalConfig.portalHeader,
        });

        // the email on the query param represents that the user is trying to
        // accept an invite for that email
        // if we are signed in with a different email, then we should log out
        if (
          query.invitedEmail &&
          decodeURI(query.invitedEmail).toLowerCase() !==
            payload.email.toLowerCase()
        ) {
          // save the failed user's email, this is used only to show error message
          window.sessionStorage.setItem(
            SIGNED_IN_WITH_GOOGLE_FAILED_EMAIL_KEY,
            payload.email,
          );

          // this user that signed in is not the one that was invited, so we the app-error
          logoutFromHostedUi(
            portalConfig,
            `${portalConfig.AWS.Auth.oauth?.redirectSignOut}?app-error=invite_login_mismatch`,
          );
          return;
        }

        // the preferred_username in cognito is always lower case
        if (
          payload.preferred_username !== expectedPreferredUsername.toLowerCase()
        ) {
          const isValid = await startProxy(payload.email);
          if (isValid) {
            // remember that we proxied this user in, so the user still has a valid session from google
            // when we logout this user, then we need to also clear the session from hosted-ui
            window.localStorage.setItem(
              SIGNED_IN_WITH_PROXY_USER_KEY,
              payload.email,
            );
            return;
          }
        }
      }

      const user: CognitoUserWithAttributes =
        await Auth.currentAuthenticatedUser();
      const { preferred_username } = user.attributes;

      // in cases of google login, the sign in will always complete
      // here, we detect scenarios where the user should not have access to the portal
      // and log the user out from cognito's hosted-ui (which maintains google session)
      if (
        // if the preferred_username does not contain the current portal id and we decided not
        // to proxy above, we logout the user and show a error message
        !preferred_username.includes(portalConfig.portalHeader.toLowerCase())
      ) {
        // save the failed user's email, this is used only to show error message
        window.sessionStorage.setItem(
          SIGNED_IN_WITH_GOOGLE_FAILED_EMAIL_KEY,
          user.attributes.email,
        );

        // this user is invalid and will not be able to sign in, so we sign him out
        // this allows him to select another account while logging in with google
        // we set the error code in the url, so that the ui can display the proper error message
        logoutFromHostedUi(
          portalConfig,
          `${portalConfig.AWS.Auth.oauth?.redirectSignOut}?app-error=user_not_found`,
        );
        return;
      }

      if (
        query.email &&
        signedInUserEmail &&
        query.email.toLowerCase() !== signedInUserEmail.toLowerCase() &&
        // skip email mismatch when magic link is used,
        // because when email mismatches we want to sign in the latest user session.
        !query.magic_link
      ) {
        // check if email is set in query path and it differs from signed in user email
        logger.debug(
          'Auth login layout loaded for email with different user',
          signedInUserEmail,
          query.email,
        );
        navigate('/');
        return;
      }

      dispatch(updateUserId(data.username));
      Hub.remove('auth', authListenerHandler);

      // identify user in flagsmith to initialize flags correctly
      // on first load.
      // This help avoids inconsistencies in flag state when using
      // segment overrides and ensures that after the user
      // has logged in they are in the appropriate flag states.
      // Without this, any flag states based on segments overrides
      // would only apply after user data is loaded. That can result
      // in flickering/switching between logic that was controlled by a flag.
      flagsmith.identify(data.username);
      flagsmith.setTrait('portal_id', portalConfig.portalHeader);
      if (!query.magic_link) {
        handleRedirect(data);
      }
    }
  };

  const initiateMagicLinkLogin = async (
    username: string,
    magicLink: string,
  ) => {
    const cu = await Auth.signIn(username, undefined, {
      type: 'magicLink',
    });
    try {
      const cognitoUser = await Auth.sendCustomChallengeAnswer(cu, magicLink, {
        type: 'magicLink',
      });
      await UsersClient.setAppUserCookie(
        cognitoUser,
        portalConfig.portalHeader,
        portalConfig.name,
        email,
      );
      handleRedirect();
    } catch (e) {
      console.info('magic link login failure', e);
    }
  };

  // check if username and password properties are provided either from
  // 1. query params: used for login from shared login page to => subdomain
  // 2. session storage: used when login from dashboard page
  const usernamePassProvided =
    // 1. email/password provided in query params
    (query && query.email && (query.password || query.auth)) ||
    (typeof window !== 'undefined' &&
      // 2. email/password provided in session storage as individual items
      window.sessionStorage.getItem('email') &&
      window.sessionStorage.getItem('password')) ||
    (typeof window !== 'undefined' &&
      // 3. email/password provided in session storage as a onboarding credentials
      window.sessionStorage.getItem('onboarding_credentials'));

  let authenticatorChildren: AuthChildComponentData[] = [
    {
      componentType: AuthComponentType.ConfirmRegisterFormComponent,
      key: 'authConfirmSignUp',
    },
  ];
  if (
    !isProcessingMagicLink &&
    ((query && step === AUTH_STATES.SIGN_UP) || action === 'signup')
  ) {
    authenticatorChildren.push({
      componentType: AuthComponentType.RegisterFormComponent,
      key: 'authSignUp',
    });
  } else if (authState === AUTH_STATES.CONFIRM_SIGN_IN) {
    // TODO: authStateData should not be repurposed as the user here.
    authenticatorChildren.push({
      componentType: AuthComponentType.VerifyMfaFormComponent,
      key: 'verifyMfa',
      extraProps: {
        user: authStateData as CognitoUser,
      },
    });
  } else if (query && step === AUTH_STATES.FORGOT_PASSWORD) {
    authenticatorChildren.push({
      componentType: AuthComponentType.ForgotPasswordFormComponent,
      key: 'forgotPassword',
    });
  } else if (
    !isProcessingAuth &&
    (usernamePassProvided || authState === AUTH_STATES.REQUIRE_NEW_PASSWORD)
  ) {
    authenticatorChildren.push({
      componentType: AuthComponentType.RequireNewPasswordFormComponent,
      key: 'authNewPassword',
    });
  } else if (!isProcessingAuth && authState === AUTH_STATES.SIGN_IN) {
    authenticatorChildren.push({
      componentType: AuthComponentType.LoginFormComponent,
      key: 'authSignIn',
    });
  }

  useEffect(() => {
    const portalId = portalConfig.portalHeader;
    if (portalId && email && magic_link) {
      Auth.configure({
        ...portalConfig.AWS.Auth,
        authenticationFlowType: 'CUSTOM_AUTH',
      });
      initiateMagicLinkLogin(`${portalId}|${email}`, magic_link);
      return;
    }
  }, [portalConfig.portalHeader, email, magic_link, link_expired]);

  useEffect(() => {
    // Listen for the auth errors
    // Errors come inside `data.payload.data.message`
    Hub.listen('auth', authListenerHandler);

    // we want to identify the client login page in flag-smith
    // to be able to disable specific features for this page.
    flagsmith.identify(
      `${CLIENT_UNAUTH_PAGE_FLAGSMITH_ID}-${portalConfig.portalHeader}`,
      {
        portal_id: portalConfig.portalHeader,
      },
    );

    return () => {
      Hub.remove('auth', authListenerHandler);
      authenticatorChildren = [];
    };
  }, []);

  return (
    <LoginLayout
      className={clsx({
        'bg-white': isAutoSignIn,
      })}
    >
      {/**
       * We show this spinner behind all auth steps, to make Authorization UX looks better
       */}
      <div className="absolute w-full h-full top-0">
        <LoaderContainer />
      </div>
      <Authenticator
        ref={authenticator}
        hideDefault
        authState={step}
        onStateChange={handleStateChange}
        authData={authStateData}
        // hide default error notifications
        errorMessage={() => null}
      >
        {authenticatorChildren}
      </Authenticator>
    </LoginLayout>
  );
};
