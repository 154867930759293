import React, { useContext, useMemo } from 'react';
import { CopilotBrandingIcon } from 'src/legacy/components/Icons';
import { Body } from 'copilot-design-system';
import { PORTAL_URL } from 'src/constants/externalUrls';
import { canDisablePortalBadge } from 'src/utils/PlansUtils';
import { PortalConfigContext } from 'src/context';
import { useGetPortalPlanQuery } from 'src/services/api';

interface Props {
  isAuthScreen: boolean;
}

export const AuthScreenPortalBadge: React.FC<Props> = ({ isAuthScreen }) => {
  const portalConfig = useContext(PortalConfigContext);
  const { data: portalPlanData, isSuccess } = useGetPortalPlanQuery({
    isAuthScreen,
  });
  /**
   * This method check for portal plan status. It call
   * get portal plan api which return if the portal is in pro
   * plan or not. And then, it updates the portal badge render condition
   * When it is a pro plan, get show badge flag prop from
   * the portal config, otherwise it should be explicitly shown.
   */

  const shouldShowPortalBadge = useMemo(() => {
    if (isSuccess) {
      const { isPro, isLegacyPaidPlan, planType } = portalPlanData;
      const canDisableBadge = canDisablePortalBadge({
        isPro,
        isLegacyPaidPlan,
        planType,
      });
      return canDisableBadge ? !portalConfig.MarketingSite.disableBadge : true;
    }
    return false;
  }, [portalPlanData, isSuccess]);

  if (!shouldShowPortalBadge) return null;

  return (
    <div className="group pr-4 z-10 relative rounded mt-6">
      <a
        href={`${PORTAL_URL}/?utm_medium=powered_by&utm_source=${encodeURIComponent(
          window.location.href,
        )}`}
        target="_blank"
        className="flex items-center gap-2 justify-center  no-underline"
      >
        <Body className="text-[12px] text-gray-small group-hover:text-black-heading lining-nums tabular-nums">
          Powered by
        </Body>
        <CopilotBrandingIcon
          style={{ height: '12px', width: '55px' }}
          className="text-gray-small group-hover:text-black-heading"
        />
      </a>
    </div>
  );
};
