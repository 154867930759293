import { AUTH_ACCESS_LINK_PAGE } from 'src/constants';
import history from 'src/history';
import { useAppDispatch } from 'src/hooks/useStore';
import {
  SendAccessLinkRequest,
  useSendAccessLinkMutation,
} from 'src/services/api/unauthApi';
import { alertSnackbar } from 'src/store/ui/actions';
import { ensureApiError } from 'src/utils/Errors';
import { capitalize } from 'src/utils/StringUtils';
export const useSendMagicLinkHandler = () => {
  const dispatch = useAppDispatch();
  const [sendAccessLink, { isLoading: isSendingMagicLink }] =
    useSendAccessLinkMutation();

  const handleSendMagicLink = async (
    accessType: SendAccessLinkRequest['accessType'],
    email: string,
    fullName?: string,
    companyName?: string,
  ) => {
    try {
      const result = await sendAccessLink({
        email,
        fullName,
        companyName,
        accessType,
      }).unwrap();

      if (result.message === 'Access link sent') {
        history.push(
          `${AUTH_ACCESS_LINK_PAGE}?email=${encodeURIComponent(
            email,
          )}&flowType=${accessType}`,
        );
      }
    } catch (error) {
      const { message: errorMessage } = ensureApiError(error);
      dispatch(alertSnackbar({ errorMessage: capitalize(errorMessage) }));
    }
  };

  return { handleSendMagicLink, isSendingMagicLink };
};
