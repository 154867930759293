import React, { useContext, useEffect } from 'react';
import { Link } from '@material-ui/core';
import clsx from 'clsx';
import { PortalConfigContext } from 'src/context';
import { getPublicImageURL } from 'src/utils/ImageUtils';

interface StyleProps {
  logoHeight?: number;
}
interface PortalFullLogoProps extends StyleProps {
  isPreviewLogo?: boolean;
  previewFullLogoUrl?: string;
}

const PortalFullLogo = ({
  isPreviewLogo = false,
  previewFullLogoUrl,
  logoHeight = 100,
}: PortalFullLogoProps) => {
  const [isImageLoaded, setIsImageLoaded] = React.useState(false);
  const portalConfig = useContext(PortalConfigContext);
  const [logoPath, setLogoPath] = React.useState('');

  useEffect(() => {
    const url = isPreviewLogo
      ? previewFullLogoUrl
      : portalConfig.assets.logo.logoImageUrl;

    if (url) {
      setLogoPath(
        getPublicImageURL(url, {
          resize: {
            height: 100,
          },
        }),
      );
    } else {
      setLogoPath('');
    }
  }, [isPreviewLogo, previewFullLogoUrl, portalConfig]);

  if (!logoPath) return null;
  return (
    <Link href="/" className="leading-0 hover:no-underline">
      <div
        className={clsx(
          'rounded-s-4 flex object-contain transition justify-center',
          { 'opacity-0': !isImageLoaded },
          { 'opacity-100': isImageLoaded },
        )}
        style={{ height: logoHeight }}
      >
        <img
          alt={portalConfig.metadata.title || portalConfig.name || 'Logo'}
          src={logoPath}
          onLoad={() => {
            setIsImageLoaded(true);
          }}
        />
      </div>
    </Link>
  );
};

export default PortalFullLogo;
