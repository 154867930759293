import React from 'react';
import { Auth } from 'aws-amplify';
import {
  CognitoHostedUIIdentityProvider,
  CognitoUser,
} from '@aws-amplify/auth';
import UsersClient from 'src/clients/UsersClient';
import history from 'src/history';
import { PortalConfigContext } from 'src/context';
import { SIGNED_IN_WITH_GOOGLE_USER_KEY } from 'src/constants/authConsts';

/**
 * This hook enables the proxy workflow to allow access to a different cognito user.
 * This should be used when a client has logged in with google but the cognito
 * user linked to their google account is connected to a different portal.
 * This is a limitation in cognito that requires us to proxy the user through
 * using the same email that was used to login with google.
 * @param pathname path that is being accessed
 * @param query query params on page
 */
export const useAdminProxy = () => {
  const proxyStarted = React.useRef(false);
  const portalConfig = React.useContext(PortalConfigContext);
  const getCodeTimer = React.useRef(0);
  React.useEffect(
    () => () => {
      if (getCodeTimer.current) {
        clearTimeout(getCodeTimer.current);
      }
    },
    [],
  );

  /**
   * This function will poll the UsersClient to see if a code has been generated.
   * The code gets generated in the createChallengeCode lambda function when the proxy
   * login is triggered. Once the code is found, it will be used to complete the
   * custom auth challenge and the user will be logged in.
   */
  const getCode = async (
    username: string,
    userStatus: string,
    user: CognitoUser,
  ) => {
    if (!username) {
      return;
    }

    try {
      const response = await UsersClient.getCustomAuthChallengeCode(username);
      if (response && response.code) {
        if (getCodeTimer.current) {
          clearInterval(getCodeTimer.current);
        }
        // found code, complete auth
        const customAuthResponse = await Auth.sendCustomChallengeAnswer(
          user,
          response.code,
        );
        console.info('custom auth response', customAuthResponse);

        // if we are proxying in a user whose password is not yet set
        // then we call the backendapi to add a password to this account
        if (userStatus === 'FORCE_CHANGE_PASSWORD') {
          if (window.localStorage.getItem(SIGNED_IN_WITH_GOOGLE_USER_KEY)) {
            await UsersClient.addPassword(
              {
                userId: user.getUsername(),
                // let the backend generate a password
                // this allows user to go through add password flow
                generatePassword: true,
                authSource: CognitoHostedUIIdentityProvider.Google,
              },
              true,
            );
          }
        }

        proxyStarted.current = false;
        let path = '/';
        const urlParams = new URLSearchParams(window.location.search);
        const next = urlParams.toString().split('&next=').pop();
        if (next) {
          path = `?next=${next}`;
        }
        history.push(path);
        return;
      }
    } catch (error) {
      // no code found or proxy failed, do nothing
      console.error(error);
    }

    getCodeTimer.current = window.setTimeout(() => {
      getCode(username, userStatus, user);
    }, 500);
  };

  const startProxy = async (proxyUserEmail: string) => {
    if (proxyStarted.current) {
      // do not fire proxy again if it has already been started
      // multiple startProxy calls can happened during signIn due to the
      // Hub event listener in Authenticator.tsx. The Hub listens for HostedUI
      // and signIn events both which are needed to trigger the proper signedIn
      // redirect. Rather than trying to ignore listening to both events, we
      // just ignore the second call to startProxy.
      return true;
    }
    proxyStarted.current = true;

    // check if the current user should proxy by confirming that the email of the proxy
    // user exists in the first place. This will do a get call to see if
    // the user exists. If the user does not exist, then we can assume that the user
    // does not belong to this portal and should not proxy.
    let proxyUsername;
    let proxyUserStatus = '';
    try {
      const user = await UsersClient.getProxyUser(proxyUserEmail);
      proxyUsername = user.PreferredUsername;
      proxyUserStatus = user.metadata.userStatus;
    } catch (error) {
      console.error(error);
      proxyStarted.current = false;
      return false;
    }

    // before configuring auth
    // lets clear the code query param from the window, so that the oauth sign in is
    // not triggerred again
    const currentParams = new URLSearchParams(window.location.search);
    currentParams.delete('code');
    window.history.replaceState(
      null,
      '',
      `${window.location.pathname}?${currentParams}`,
    );

    Auth.configure({
      ...portalConfig.AWS.Auth,
      authenticationFlowType: 'CUSTOM_AUTH',
    });

    const user = await Auth.signIn(proxyUsername);
    if (user.challengeName === 'CUSTOM_CHALLENGE' && user.challengeParam) {
      await getCode(proxyUsername, proxyUserStatus, user);
    }
    return true;
  };

  return { startProxy };
};
