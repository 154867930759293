import React, { FC, useCallback, useEffect, useState, useContext } from 'react';
import { I18n, Auth } from 'aws-amplify';
import { Box, Theme, makeStyles, createStyles } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { AUTH_STATES } from 'src/constants/authConsts';
import history from 'src/history';
import { authScreenStyles } from 'src/legacy/components/Auth/styles';
import { LOGIN_PAGE } from 'src/constants';
import { PortalConfigContext, RouteContext, ContextProps } from 'src/context';
import { CarouselLayout } from 'src/legacy/components/Carousel';
import { BaseTextField, PasswordField } from 'src/legacy/components/TextField';
import Button from 'src/legacy/components/Button';
import { AuthLinkActionButton } from 'src/legacy/components/Auth/AuthLinkActionButton';
import { IAuthenticatorReturn } from 'src/legacy/components/AwsAmplify';
import {
  getPreferredUsername,
  GetSignInMetadata,
  GetSignInMetadataInput,
} from 'src/utils/AuthUtils';
import { ClientAuthContainer } from 'src/legacy/components/Auth/ClientAuthContainer';

interface IState {
  isLoading?: boolean;
  errorMessage?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...authScreenStyles(theme),
  }),
);

const accountFormSchema = Yup.object().shape({
  username: Yup.string()
    .email('Invalid email')
    .max(100)
    .required('Email is required'),
});

const confirmFormSchema = Yup.object().shape({
  code: Yup.string()
    .trim()
    .min(6, 'Code must be exactly 6 digits.')
    .max(6, 'Code must be exactly 6 digits.')
    .required('Code is required'),
  password: Yup.string()
    .max(256, 'Password must be less than 256 characters')
    .min(8, 'Password must be at least 8 characters')
    .matches(/[a-z]/, 'Password must have at least one lowercase char')
    .matches(/[A-Z]/, 'Password must have at least one uppercase char')
    .matches(/[0-9]+/, 'Password must have at least one number')
    .required('Password is required'),
});

const validAuthState = ['forgotPassword'];

const ForgotPasswordForm: FC<IAuthenticatorReturn> = (props) => {
  const { onStateChange, inputs, handleInputChange, authState } = props;
  const classes = useStyles();
  const context: ContextProps = useContext(RouteContext);

  // Using this state to determine which form to show to the user
  const [delivery, setDelivery] = useState<string | null>(null);

  const [state, setState] = useState<IState>({
    isLoading: false,
    errorMessage: 'Username not found',
  });

  const portalConfig = useContext(PortalConfigContext);

  const handleGoToSignIn = () => {
    onStateChange(AUTH_STATES.SIGN_IN);
    history.push(LOGIN_PAGE);
  };

  useEffect(() => {
    onStateChange(AUTH_STATES.FORGOT_PASSWORD);
  }, []);

  const handleSendCode = async () => {
    const { username } = inputs;

    if (!username) return;

    if (!Auth || typeof Auth.forgotPassword !== 'function') {
      throw new Error(
        'No Auth module found, please ensure @aws-amplify/auth is imported',
      );
    }

    try {
      setState({ isLoading: true });
      const preferredUsername = getPreferredUsername({
        email: username,
        viewMode: portalConfig.viewMode,
        portalId: portalConfig.portalHeader,
      });
      const data = await Auth.forgotPassword(
        preferredUsername,
        GetSignInMetadata(context.query as GetSignInMetadataInput),
      );

      if (!data) return;

      setDelivery(data.CodeDeliveryDetails.DeliveryMedium);
      setState({ isLoading: false });
    } catch (error) {
      setState({ isLoading: false });
    }
  };

  const ConfirmForm = useCallback(
    () => (
      <Formik
        initialValues={{
          code: '',
          password: '',
        }}
        validationSchema={confirmFormSchema}
        onSubmit={async (_, { setStatus }) => {
          try {
            setState({ isLoading: true });

            const { code, password, username } = inputs;
            if (!username || !password || !code) return;

            const preferredUsername = getPreferredUsername({
              email: username,
              viewMode: portalConfig.viewMode,
              portalId: portalConfig.portalHeader,
            });
            await Auth.forgotPasswordSubmit(
              preferredUsername,
              code,
              password,
              GetSignInMetadata(context.query as GetSignInMetadataInput),
            );
            onStateChange('autoSignIn', {
              username: preferredUsername,
              password,
            });
            setState({ isLoading: false });
          } catch (error) {
            setStatus({ success: false });
            setState({ isLoading: false });
          }
        }}
      >
        {({
          values,
          errors,
          handleBlur,
          handleSubmit,
          touched,
          handleChange,
        }) => {
          const isConfirmEnabled =
            values.code && !errors.code && values.password && !errors.password;
          return (
            <form noValidate onSubmit={handleSubmit}>
              <Box className={classes.fields}>
                <Box>
                  <BaseTextField
                    fullWidth
                    sizeVariant="tall"
                    type="text"
                    key="code"
                    name="code"
                    variant="outlined"
                    onBlur={handleBlur}
                    onInput={handleChange}
                    onChange={handleInputChange}
                    label={I18n.get('Code')}
                    error={Boolean(touched.code && errors.code)}
                    helperText={(touched.code && errors.code) || ' '}
                    autoComplete="off"
                    autoFocus
                  />
                  <PasswordField
                    fullWidth
                    sizeVariant="tall"
                    type="password"
                    key="password"
                    name="password"
                    variant="outlined"
                    onBlur={handleBlur}
                    onInput={handleChange}
                    onChange={handleInputChange}
                    label={I18n.get('New password')}
                    error={Boolean(touched.password && errors.password)}
                    helperText={(touched.password && errors.password) || ' '}
                    autoComplete="off"
                    inputProps={{
                      autoComplete: 'new-password',
                    }}
                  />
                </Box>
              </Box>
              <Box width={1} mt={2.5}>
                <Button
                  size="large"
                  className={classes.submitButton}
                  fullWidth
                  htmlId="forgot-password-submit"
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={!isConfirmEnabled}
                  isLoading={state.isLoading}
                >
                  {I18n.get('Reset password')}
                </Button>
              </Box>
            </form>
          );
        }}
      </Formik>
    ),
    [inputs, state, onStateChange],
  );

  const AccountForm = useCallback(
    () => (
      <Formik
        initialValues={{
          username: '',
        }}
        validationSchema={accountFormSchema}
        onSubmit={async (_, { setStatus, resetForm }) => {
          try {
            setState({ isLoading: true });
            await handleSendCode();
            setState({ isLoading: false });
            resetForm();
          } catch (error) {
            setStatus({ success: false });
            setState({ isLoading: false });
            resetForm();
          }
        }}
      >
        {({
          values,
          errors,
          handleBlur,
          handleSubmit,
          touched,
          handleChange,
        }) => {
          const isSendEnabled = values.username && !errors.username;

          return (
            <form noValidate onSubmit={handleSubmit}>
              <Box className={classes.fields}>
                <Box>
                  <BaseTextField
                    fullWidth
                    sizeVariant="tall"
                    type="text"
                    key="username"
                    name="username"
                    variant="outlined"
                    onBlur={handleBlur}
                    onInput={handleChange}
                    onChange={handleInputChange}
                    label={I18n.get('Email')}
                    error={Boolean(touched.username && errors.username)}
                    helperText={(touched.username && errors.username) || ' '}
                    autoComplete="off"
                    autoFocus
                  />
                </Box>
              </Box>
              <Box mt={2.5}>
                <Button
                  className={classes.submitButton}
                  fullWidth
                  htmlId="forgot-password-send-code"
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={!isSendEnabled}
                  isLoading={state.isLoading}
                >
                  {I18n.get('Next')}
                </Button>
              </Box>
            </form>
          );
        }}
      </Formik>
    ),
    [inputs, state],
  );

  // enable next button only if user has entered a valid username
  // and the code has been sent
  const checkNextEnabled = (currentStep: number) => {
    if (currentStep === 0) {
      if (inputs.username || delivery) return true;
      return false;
    }

    return false;
  };

  if (!validAuthState.includes(authState)) return null;

  return (
    <ClientAuthContainer
      title="Reset your password"
      description="A verification code will be sent to your email."
    >
      <Box className={classes.formContainer}>
        <CarouselLayout
          hideAllActions
          length={2}
          nextEnabled={checkNextEnabled}
          step={delivery ? 1 : 0} // if delivery is set, go to second step
          hideIndicators
        >
          <Box>
            {AccountForm()}
            <AuthLinkActionButton
              linkText="Back to sign in"
              onClick={handleGoToSignIn}
              className={classes.navigationActionsContainerMobile}
            />
          </Box>
          <Box>
            {ConfirmForm()}
            <AuthLinkActionButton
              linkText="Back to sign in"
              onClick={handleGoToSignIn}
              className={classes.navigationActionsContainerMobile}
            />
          </Box>
        </CarouselLayout>
      </Box>
      <AuthLinkActionButton
        linkText="Back to sign in"
        onClick={handleGoToSignIn}
        className={classes.navigationActionsContainerDesktop}
      />
    </ClientAuthContainer>
  );
};

export default ForgotPasswordForm;
