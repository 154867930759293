import React from 'react';
import { Container, makeStyles, createStyles } from '@material-ui/core';
import * as Colors from 'src/theme/colors';
import clsx from 'clsx';

const useStyles = makeStyles((theme) =>
  createStyles({
    '@global': {
      '*': {
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
    },
    body: {
      display: 'flex',
      justifyContent: 'center',
      height: '100%',
      minHeight: '100%',
      fontFamily: ['"Inter"', '"Helvetica"', '"Arial"', 'san-serif'].join(','),
      backgroundColor: Colors.white,
      ['@media screen and (min-height: 700px)']: {
        alignItems: 'center',
      },
    },
    container: {
      [theme.breakpoints.down('xs')]: {
        padding: 0,
        height: '100%',
      },
    },
  }),
);

interface LoginPageLayoutProps {
  className?: string;
}

export const LoginPageLayout: React.FC<LoginPageLayoutProps> = ({
  children,
  className,
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.body, className)}>
      <Container className={classes.container} data-test-id="login-page-layout">
        {/* This is gross and ugly but the recommended approach for handling the TS error
         of React.Node is not assignable to NonNullable.. coming from MUI Container
         https://github.com/DefinitelyTyped/DefinitelyTyped/issues/18051#issuecomment-449628575 */}
        {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
        <>{children}</>
      </Container>
    </div>
  );
};
